<template>
  <component :is="viewName" @cut="cut" :title="type" :data="data"></component>
</template>

<script>
import PatterList from './children/PatterList'
import PatterEdit from './children/PatterEdit'
export default {
  name: 'PatterView',
  data () {
    return {
      viewName: 'PatterList',
      type: '',
      data: ''
    }
  },
  components: {
    PatterList,
    PatterEdit
  },
  methods: {
    cut (name, type, data) {
      this.viewName = name
      this.type = type
      this.data = data
    }
  }
}
</script>

<style scoped>

</style>
