<template>
  <a-card :bordered="false">
    <p class="title">{{ title }}话术</p>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="{span:2}"
      :wrapper-col="{span:22}"
    >
      <a-form-model-item label="话术名称" prop="name">
        <a-input v-model="form.name" :disabled="title==='查看'"/>
      </a-form-model-item>
      <a-form-model-item label="模板描述">
        <div style="line-height: normal">
          <div v-if="title==='查看'" v-html="form.talkSkill" class="view"></div>
          <vue-ueditor-wrap v-else v-model="form.talkSkill" :config="myConfig"></vue-ueditor-wrap>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="footer">
      <a-button @click="submit" v-show="title!=='查看'">确定</a-button>
      <a-button @click="back">返回</a-button>
    </div>
  </a-card>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import { add } from '@/api/template/skill'

export default {
  name: 'PatterEdit',
  props: {
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    VueUeditorWrap
  },
  watch: {
    data (n) {
      console.log(n)
    }
  },
  data () {
    return {
      form: {
        id: null,
        name: '',
        talkSkill: ''
      },
      rules: {
        name: [
          { required: true, message: '话术名称不能为空', trigger: 'blur' }
        ]
      },
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: 'http://35.201.165.105:8000/controller.php',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/UEditor/'
      }
    }
  },
  mounted () {
    console.log(this.data)
    if (this.data) {
      this.form = this.data
    }
  },
  methods: {
    submit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          delete this.form.entryTime
          add(this.form).then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$emit('cut', 'PatterList')
            this.$notification.success({
              message: '提示',
              description: res.message
            })
          })
        } else {
          return false
        }
      })
    },
    back () {
      this.$emit('cut', 'PatterList')
    }
  }
}
</script>

<style scoped>
  .title {
    text-align: center;
    font-size: 30px;
  }

  .footer {
    text-align: center;
  }

  .footer button {
    margin: 30px;
  }

  .view {
    border: 1px solid #ddd;
    padding: 10px 0;
  }
</style>
<style lang="less">
  .view {
    word-wrap: break-word;
    word-break: normal;
    .selectTdClass {
      background-color: #edf5fa !important
    }

    table.noBorderTable td, table.noBorderTable th, table.noBorderTable caption {
      border: 1px dashed #ddd !important
    }

    table {
      margin-bottom: 10px;
      border-collapse: collapse;
      display: table;
    }

    td, th {
      padding: 5px 10px;
      border: 1px solid #DDD;
    }

    caption {
      border: 1px dashed #DDD;
      border-bottom: 0;
      padding: 3px;
      text-align: center;
    }

    th {
      border-top: 1px solid #BBB;
      background-color: #F7F7F7;
    }

    table tr.firstRow th {
      border-top-width: 2px;
    }

    .ue-table-interlace-color-single {
      background-color: #fcfcfc;
    }

    .ue-table-interlace-color-double {
      background-color: #f7faff;
    }

    td p {
      margin: 0;
      padding: 0;
    }
  }
</style>
